import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

function About(){
    const { t, i18n } = useTranslation();

    return(

    <section className="page-section text-white mb-0" id="about">
    <div className="container">
  
        <h2 className="page-section-heading text-center text-uppercase text-white font-face-francois">{t('About')}</h2>
  
        <div className="divider-custom divider-light">
            <div className="divider-custom-line"></div>
            <div className="divider-custom-icon"><i className="fa fa-id-badge"></i></div>
            <div className="divider-custom-line"></div>
        </div>

        <div className="row">
            <div className="col-lg-4 ms-auto">
                <p className="lead  font-face-opensans ">
                {t('About1')}<br></br><br></br>{t('About2')}
                <br></br><br></br>{t('About3')}
                </p>
            </div>
            <div className="col-lg-4 me-auto">
                <p className="lead font-face-opensans ">{t('About4')}
                <br></br><br></br> {t('About5')}<br></br><br></br> {t('About6')}
                </p>
            </div>
        </div>
 

        <div className="text-center mt-4">
        <Dropdown>
            <Dropdown.Toggle className="btn btn-xl btn-outline-light"  id="dropdown-basic">
            <i className="fas fa-download me-2"></i>
                {t('CV')}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item className="cvoption font-face-opensans " target= "_blank" href="./CVCeciliaGarciaEng.pdf">{t('Inglés')}</Dropdown.Item>
                <Dropdown.Item className="cvoption font-face-opensans "  target= "_blank" href="./CVCeciliaGarciaEsp.pdf">{t('Español')}</Dropdown.Item>
                
            </Dropdown.Menu>
        </Dropdown>
        </div>
    </div>
</section>
    )
}

export default About
