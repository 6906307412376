import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';

function GalleryItem(props){
    const { t, i18n } = useTranslation();




    return(
    <div className="portfolio-modal modal fade" data-bs-keyboard="false" data-bs-backdrop="static" id={props.itemid} tabIndex="-1" aria-labelledby={props.itemid} aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content modalnoborder">
                <div className="modal-header border-0"><button  onClick={props.closeVideo} id="stopbutton" className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                <div className="modal-body text-center ">
                    <div className="container">
                    <div className="row justify-content-center">
                    </div>
                    <div  id="video1container" className=" row justify-content-center">      
                      
                    <ReactPlayer  width="560" height="315" id="video1" className=" img-fluid rounded "  title="YouTube video player" frameBorder="0"  allowFullScreen   url={props.source} playing={props.play}/>
                    </div>   
                    <p className="m-1 portfolioitemtitle"><b>{props.title}</b></p>
                    <p className="mb-1 portfolioitemdescription"> <b>{props.desc1} </b></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
)}

export default GalleryItem;