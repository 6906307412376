
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './i18n';
import React, {Suspense} from 'react';
import { Spinner } from 'react-bootstrap';
import './fonts/OpenSans/OpenSansVariable.ttf';
import './fonts/FrancoisOne/FrancoisOne-Regular.ttf';
import 'font-awesome/css/font-awesome.min.css';

ReactDOM.render(
  <Suspense fallback={(<div className= "loaderContainer"><Spinner id= "spinner" animation="border" role="status"></Spinner></div>)}>
  <App/>
  </Suspense>,
  document.getElementById('root')
  
);
